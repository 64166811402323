<template>
  <div>
    <!--begin::Signin-->
        <div class="login-form">
          <!--begin::Form-->
            <b-form class="form" @submit.stop.prevent="onSubmit">
              <!--begin::Title-->
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg">{{ $t("auth.title.login_title") }}</h3>
              </div>
              <!--begin::Title-->

              <b-form-group>
                <!--begin::Form group-->
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">{{ $t("label.username") }}</label>
                  <b-form-input 
                    class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" 
                    type="text" 
                    name="email" 
                    autocomplete="off"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="email-live-feedback"
                    :placeholder="$t('auth.placeholder.login')"
                    >
                  </b-form-input>
                </div>
                <!--end::Form group-->
              </b-form-group>

              <b-form-group>
                <!--begin::Form group-->
                <div class="form-group mb-0">
                  <div class="d-flex justify-content-between mt-n5">
                    <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{ $t("label.password") }}</label>
                    <router-link :to="{ name: 'forgot_password'}"
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    >
                      {{ $t("auth.title.forgot_password") }}
                    </router-link>
                  </div>
                  <b-form-input 
                    class="form-control form-control-solid h-auto py-6 px-6 rounded-lg" 
                    type="password" 
                    autocomplete="off" 
                    id="password"
                    name="password"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="password-live-feedback"
                    :placeholder="$t('auth.placeholder.password')"
                    />
                </div>
                <!--end::Form group-->
              </b-form-group>

              <div
                role="alert"
                v-bind:class="{ show: errors.length }"
                class="alert fade alert-danger"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>


              <!--begin::Action-->
              <div class="pb-lg-0 pb-5">
                <button 
                  ref="kt_login_signin_submit"
                  class="btn btn-elevate btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                  >
                  {{ $t("button.login") }}
                </button>
              </div>
              <!--end::Action-->
            </b-form>
          <!--end::Form-->
        </div>
        <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: process.env.NODE_ENV === "production" ? "" : "admin",
        password: process.env.NODE_ENV === "production" ? "" : "Password3122@"
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  metaInfo () { 
      return { title: this.$t("auth.meta.login")} 
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const account = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        var url = (this.lastUrl) ? this.lastUrl : "/dashboard";
        this.$store
          .dispatch(LOGIN, { account, password })
          // go to which page after successfully login
          .then(response => {
            this.$toasted.success(response.message);
            this.$router.push({ path: url })
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 500);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      lastUrl: state => state.auth.lastUrl
    })
  }
};
</script>
